import React from 'react'
import { useChangeInView } from '../context/currentSectionContext'

function NavigationSectionWrapper({ children, name }) {
    const { ref } = useChangeInView({ name })
    
    return (
        <div id={name} ref={ref}>
            {children}
        </div>
    )
}


export default NavigationSectionWrapper