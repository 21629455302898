import React, { createContext, useContext, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const defaultSection = {
    currentSection: '',
    setCurrentSection: () => { },
    sections: []
}

export const CurrentSectionContext = createContext(defaultSection)

const CurrentSectionContextWrapper = ({ children, sections }) => {

    const [currentSection, setCurrentSection] = useState(defaultSection.currentSection)

    return (
        <CurrentSectionContext.Provider value={{ currentSection, setCurrentSection, sections }}>
            {children}
        </CurrentSectionContext.Provider>
    )
}

export const createSections = (sections) => {
    const reducedSections = sections.reduce((prev, curr) => {
        return { ...prev, [curr]: curr }
    }, {})
    return reducedSections
}

export const useCurrentSection = () => {
    const { currentSection } = useContext(CurrentSectionContext)
    return { currentSection }
}

export const useSetCurrentSection = () => {
    const { setCurrentSection, sections } = useContext(CurrentSectionContext)
    return { setCurrentSection, sections }
}

export const useChangeInView = ({ name, options = {} }) => {

    const { ref, inView } = useInView({ threshold: [0, 1], ...options });
    const { setCurrentSection, sections } = useSetCurrentSection()

    useEffect(() => {
        if (inView) {
            checkIfValid(name, sections, () => setCurrentSection(name))
        }
    }, [inView, sections, setCurrentSection, name])

    return { ref }
}

const checkIfValid = (name, sections, fn) => {
    if (sections[name]) {
        return fn()
    }
    throw Error('use SECTIONS to link sections')
}

export default CurrentSectionContextWrapper



