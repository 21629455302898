import React from 'react'
import OverlayConatiner from './overlayConatiner'
import Overlay from './overlay'
import VideoOrImage from './videoOrImage'
import classNames from 'classnames'

export default function HalfCard({ as, media, children, imgClassName = '', className, ratio = 'ratio-1-2 t-pt100 m-pt150 ', ...props }) {

    const overlayClassNames = classNames(
        ratio,
        'bg-grey ',
        className
    )

    return (
        <OverlayConatiner as={as} className={overlayClassNames} {...props}>
            <Overlay className='z-2 p60 t-p20 text-white border-box m-p20'>
                {children}
            </Overlay>
            <VideoOrImage mobileImage={media.mobileImage} mobileVideo={media.mobileVideo} videoWhen={media.video} video={media.video} image={media.image} className={'bg-image filter-brightness z-1 cover ' + imgClassName} />
        </OverlayConatiner>
    )
}
