import React from 'react'
import ReactMarkdown from 'react-markdown'

export default function MissionStatment({ statement }) {
    return (
        <div className='bg-white pt200 p60 m-p20 pb100  m-pl20 m-pr20 m-pt80 m-pb100'>
            <ReactMarkdown className='h3 text-grey ma max-1000 m-text-left m-text-black m-h3 post--content'>
                {statement}
            </ReactMarkdown>
        </div>
    )
}
