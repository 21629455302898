import { graphql } from "gatsby"
import React from "react"
import ChangeHeaderInView from "../components/changeHeaderinView"
import HeroBanner from "../components/heroBanner"
import LatestNews from "../components/latestNews"
import Layout from "../components/layout"
import MissionStatment from "../components/missionStatment"
import NavigationSectionWrapper from "../components/navigationSectionWrapper"
import SecondaryNavigation from "../components/secondaryNavigation"
import Seo from "../components/seo"
import Story from "../components/story"
import Team from "../components/team"
import CurrentSectionContextWrapper, { createSections } from "../context/currentSectionContext"
import { COLOR_STATES } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"

export default function About({ data }) {
    const { isJapanese, switchLanguage } = useLanguage()
    const englishSections = data.about.tabs.map(tab => tab.title.toLowerCase())
    const japaneseSections = data.about.tabs.map(tab => tab.titleJp.toLowerCase())
    const sections = isJapanese ? createSections([...japaneseSections]) : createSections([...englishSections])
    return (
        <CurrentSectionContextWrapper sections={sections}>
            <Layout>
                <Seo title='About FSCO' />
                <HeroBanner mobileVideo={data.about.mobileHeroVideoUrl} mobileImage={data.about.mobileHeroImage} title={switchLanguage(data.about.heroTitleJp, data.about.heroTitle)} image={data.about.heroImage} video={data.about.heroVideoUrl} />
                <ChangeHeaderInView threshold={0.1} color={COLOR_STATES.DARK_NO_BG}>
                    <SecondaryNavigation sections={sections} />

                    <NavigationSectionWrapper name={sections[switchLanguage(japaneseSections[0], englishSections[0])]}>
                        <MissionStatment statement={switchLanguage(data.about.introductionJp, data.about.introduction)} />
                        <Story statement={switchLanguage(data.about.storyJp, data.about.story)} />
                    </NavigationSectionWrapper>

                    <NavigationSectionWrapper name={sections[switchLanguage(japaneseSections[1], englishSections[1])]}>
                        <Team leadershipTeam={data.leadershipTeam.nodes} team={data.team.nodes} showTeamMembers={data.about.showTeamMembers} title={switchLanguage(data.about.leadershipTitleJp, data.about.leadershipTitle)} teamTitle={switchLanguage(data.about.teamTitleJp, data.about.teamTitle)} />
                    </NavigationSectionWrapper>

                    <NavigationSectionWrapper name={sections[switchLanguage(japaneseSections[2], englishSections[2])]}>
                        <LatestNews className='pb160 m-pb30' title={switchLanguage(data.about.newsTitleJp, data.about.newsTitle)} news={data.about.news} />
                    </NavigationSectionWrapper>
                </ChangeHeaderInView>
            </Layout>
        </CurrentSectionContextWrapper>
    )
}

export const query = graphql`
    query About {
        pageData: datoCmsAboutPage {
            subtitle
            subtitleJp
        }
        about: datoCmsAboutPage {
            showTeamMembers
            mobileHeroVideoUrl
            mobileHeroImage {
                gatsbyImageData
            }
            tabs {
                id
                title
                titleJp
            }
            heroTitle
            heroTitleJp
            story
            storyJp
            leadershipTitle
            leadershipTitleJp
            teamTitle
            teamTitleJp
            newsTitle
            newsTitleJp
            heroVideoUrl
            heroImage {
                gatsbyImageData
            }
            introduction
            introductionJp
            news {
                ...NewsCard
            }
        }
        leadershipTeam: allDatoCmsLeadershipTeam(sort: { fields: position }) {
            nodes {
                id
                role
                roleJp
                name
                nameJp
                image {
                    gatsbyImageData
                }
                description
                descriptionJp
            }
        }
        team: allDatoCmsTeam(sort: { fields: position }) {
            nodes {
                id
                name
                nameJp
                role
                roleJp
                image {
                    gatsbyImageData
                }
            }
        }
    }
`
