import React, { memo, useCallback } from "react"
import { useCurrentSection } from "../context/currentSectionContext"

const SecondaryNavigation = ({ sections }) => {

  return (
    <div className='secondary--navigation bb1 z-3 bg-white m-hide z-2  ' >
      <div className='grid grid-3 max-1600 ma pl60 pr60 h-100 '>
        <div className='' />
        <div className='secondary--menu flex justify-center'>
          {sections ? <SecondaryMenu sections={sections} /> : null}
        </div>

      </div>
    </div >
  )
}

const SecondaryMenu = ({ sections }) => {
  const { currentSection } = useCurrentSection()

  return (
    <>
      {Object.values(sections).map((section, index) => {
        return (
          <SecondaryLink name={section} key={index} active={currentSection === section} >
            {section}
          </SecondaryLink>
        )
      })}
    </>
  )
}

const SecondaryLink = memo(({ active, name, children, ...props }) => {

  const scrollTo = useCallback(() => {
    document.querySelector(`#${name}`).scrollIntoView({ behavior: "smooth", })
  }, [name])

  return (
    <button onClick={scrollTo} className={'secondary--link t-hide m0 ' + (active ? 'active' : '')}  {...props}>
      {children}
    </button>
  )
})
export default SecondaryNavigation
