export function getCtaDataHome(data) {
    return {
        video: data.videoUrl,
        image: data.image,
        subHeading: data.subHeading,
        heading: data.heading,
        icon: data.icon
    }
}

export function getJapaneseCtaDataHome(data) {
    return {
        video: data.videoUrl,
        image: data.image,
        subHeading: data.subHeadingJap,
        heading: data.headingJap,
        icon: data.icon
    }
}

export function getProductDataThumbnail(data) {
    return data.allDatoCmsOffering.nodes
}

export function resolveToMedia(productThumbnail) {
    return {
        video: productThumbnail.featuredVideoUrl,
        image: productThumbnail.featuredImage
    }
}

export function resolveTeamMedia(team) {
    return {
        video: null,
        image: team.image
    }
}

export function resolveToTeam(team) {
    return team.nodes
}

export function resolveToPartneredBrands(brands) {
    return brands.nodes
}

export function resolveToTrustedBrands(brands) {
    return brands.nodes
}


export function resolveToFeatureInfo(product) {
    return {
        title: product.featureTitle,
        titleJp: product.featureTitleJp,
        description: product.featureDescription,
        descriptionJp: product.featureDescriptionJp
    }
}

export function resolveToTestiomial(product) {
    return {
        quote: product.quote,
        quoteJp: product.quoteJp,
        detail: product.customerDetail,
        detailJp: product.customerDetailJp,
        name: product.customerName,
        nameJp: product.customerNameJp,
        image: product.image,
        mobileImage: product.mobileImage
    }
}

export function resolveToFeaturedNews(data) {
    return data.featuredNews.nodes[0]
}