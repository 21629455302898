import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { useLanguage } from "../hooks/useLanguage"
import { resolveTeamMedia } from "../utils/dataFetchers"
import { H3, P } from "./fonts"
import HalfCard from "./halfCard"

export default function Team({ leadershipTeam, team, showTeamMembers, title, teamTitle }) {
    return (
        <div className='grid grid-cols-1  gap-y-80 pl60 pr60 pt160 pb160 max-1600 ma m-p20 m-pb60 m-pt60 m-gap-y-30 '>
            <p className='h2'>{title}</p>
            {leadershipTeam.map((teamMember, index) => {
                return <TeamMember key={index} team={teamMember} />
            })}
            {showTeamMembers && (
                <div>
                    <p className='h2 mt40 mb40'>{teamTitle}</p>
                    <div className='grid grid-3 gap-x-30 gap-y-80'>
                        {team.map(member => (
                            <Member key={member.id} member={member} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

function Member({ member }) {
    const { switchLanguage } = useLanguage()
    return (
        <div className='flex flex-col'>
            <picture className='aspect-2-1 pos-rel overflow br-10'>
                <GatsbyImage image={member.image.gatsbyImageData} className='bg-image br-10' />
            </picture>
            <p className='m0 mt20 h4'>{switchLanguage(member.nameJp, member.name)}</p>
            <p className='m0 text-grey'>{switchLanguage(member.roleJp, member.role)}</p>
            <p></p>
        </div>
    )
}

function TeamMember({ team }) {
    const { switchLanguage } = useLanguage()
    return (
        <div className='grid grid-cols-2 gap-x-30 m-flex m-wrap m-col-reverse'>
            <div className='flex flex-col justify-between m-mb60 '>
                <div className='mb30'>
                    <H3>{switchLanguage(team.nameJp, team.name)}</H3>
                    <P className='text-green'>{switchLanguage(team.roleJp, team.role)}</P>
                </div>
                <P className='max-510'>{switchLanguage(team.descriptionJp, team.description)}</P>
            </div>
            <HalfCard ratio='ratio-1-1' className='m-100 m-mb30' media={resolveTeamMedia(team)} />
        </div>
    )
}

export const query = graphql`
    fragment TeamMember on DatoCmsLeadershipTeam {
        name
        description
        role
        image {
            gatsbyImageData
        }
    }
`
