import React from 'react'
import ReactMarkdown from 'react-markdown'

export default function Story({ statement }) {
    return (
        <div className='bg-white  pb200 p60 m-p20 m-pl20 m-pr20 m-pb100'>
            <ReactMarkdown className='text-grey p ma max-1000 m-text-left m-text-black m-h3 post--content'>
                {statement}
            </ReactMarkdown>
        </div>
    )
}
